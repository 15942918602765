import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { LoadingService } from '@services/loading.service';
import { FormAlert } from '../alert/alert.component';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [],
  template: '',
})
export class FormComponent {
  endpoint: string = '';
  method: string = 'post';
  alertData: FormAlert | null = null;
  data: any = {};
  form: any;

  constructor(
    protected api: ApiService,
    protected loadingService: LoadingService,
    protected formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.settings();
  }

  settings() {}

  isValid(): boolean {
    return true;
  }

  getParams() {
    return this.data;
  }

  submit() {
    if (this.loadingService.getLoading() || !this.isValid()) return;

    this.startLoading();

    const params = this.getParams();

    this.api.call(this.endpoint, this.method, params).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.success(response);
        } else {
          this.failed(response.message);
        }
      },
      error: (error) => {
        this.failed(error);
      },
    });
  }

  success(response: any) {
    this.stopLoading();
  }

  failed(error: any) {
    this.handleAlert(
      typeof error === 'string'
        ? error || '¡Ocurrio un error!'
        : '¡Ocurrio un error!'
    );

    this.stopLoading();
  }

  handleAlert(message: string, type: string = 'danger') {
    const options = {
      closeButton: true,
      positionClass: 'toast-top-center',
    };

    switch (type) {
      case 'success':
        this.toastr.success(message, '', options);
        break;

      case 'info':
        this.toastr.info(message, '', options);
        break;

      case 'warning':
        this.toastr.warning(message, '', options);
        break;

      case 'danger':
        this.toastr.error(message, '', options);

        break;

      default:
        this.toastr.info(message, '', options);

        break;
    }

    // olders
    return;
    this.alertData = new FormAlert();

    this.alertData = {
      message,
      type,
    };
  }

  startLoading() {
    this.loadingService.start();
  }

  stopLoading() {
    this.loadingService.stop();
  }
}
