import { CommonModule } from '@angular/common';
import { Component, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MainModalComponent } from '../main-modal/main-modal.component';

@Component({
  selector: 'app-confirm-modal',
  standalone: true,
  imports: [CommonModule, MainModalComponent],
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  @Input() cancelBtn: string = 'Cancel';
  @Input() cancelClass: string = 'btn-light';
  @Input() hasCancel: boolean = true;
  @Input() confirmBtn: string = 'Accept';
  @Input() confirmClass: string = 'btn-primary';
  @Input() message: string = '';
  @Input() title: string = '';

  constructor(private activeModal: NgbActiveModal) {}

  confirm() {
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.close(false);
  }
}
