import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loading: boolean = false;
  private _label: string = '';
  loader = signal(false);

  constructor() {}

  start() {
    setTimeout(() => {
      this.loading = true;
      this.loader.set(true);
    }, 0);
  }

  stop() {
    setTimeout(() => {
      this.loading = false;
      this.loader.set(false);
    }, 0);
  }

  setLoading(loading: boolean) {
    setTimeout(() => {
      this.loading = loading;
      this.loader.set(loading);
    }, 0);
  }

  getLoading(): boolean {
    return this.loading;
  }

  public get label(): string {
    return this._label;
  }
  public set label(value: string) {
    this._label = value;
  }
}
