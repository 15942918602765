<div class="modal-content" aria-modal="true">
  <div class="modal-header" role="heading">
    <h5 class="modal-title">{{ title }}</h5>

    <i
      class="fa-solid fa-xmark fa-xl clickable"
      role="button"
      aria-label="Close modal"
      (click)="onClose()"
      (keyup.enter)="onClose()"
    ></i>
  </div>

  <div class="modal-body">
    <ng-content select="[content]"></ng-content>
  </div>

  <div class="modal-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
